<template>
  <div class="mine-contract h-screen flex flex-col">
    <div class="flex-shrink-0">
      <Navbar title="合同"> </Navbar>
    </div>
    <div class="h-full flex-1 overflow-auto">
      <Tabs v-model="active" @click="handleChangeTab" line-width="50%" sticky offset-top="0.44rem">
        <Tab title="已签约" name="NORMAL" class="pa-16">
          <div v-if="contractList['NORMAL'].length">
            <ConstractCard v-for="(item, index) in contractList['NORMAL']" :cardDetail="item" :key="index" />
          </div>
          <div v-else>
            <Empty imgWidth="0.84rem" :image="noContract" description="当前无合同" />
          </div>
        </Tab>
        <Tab title="待签约" name="UNSIGN" class="pa-16">
          <div v-if="contractList['UNSIGN'].length">
            <ConstractCard v-for="(item, index) in contractList['UNSIGN']" :cardDetail="item" :key="index" />
          </div>
          <div v-else>
            <Empty imgWidth="0.84rem" :image="noContract" description="当前无合同" />
          </div>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import { Button } from 'vant';
import { Tab, Tabs } from 'vant';
import ConstractCard from '../components/contractCard';
import { getConstractList } from '@api/mine';
import { useUserStore } from '@/store/user';

export default defineComponent({
  name: 'MineContract',
  components: {
    Button,
    Tab,
    Tabs,
    ConstractCard
  },
  setup() {
    const noContract = require('@/assets/htkb.png');
    const active = ref('NORMAL');
    const contractList = ref({ NORMAL: [], UNSIGN: [] });
    const userStore = useUserStore();

    const handleChangeTab = e => {
      active.value = e;
    };

    getConstractList({
      driverId: userStore.driverId
    }).then(res => {
      contractList.value = res.data;
    });
    return {
      active,
      noContract,
      contractList,
      handleChangeTab
    };
  }
});
</script>
<style lang="less">
.mine-contract {
  min-height: 100vh;
  background: #f1f3f5;
}
</style>
