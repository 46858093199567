<template>
  <div
    @click.stop="() => jumpPdf(cardDetail.attachment)"
    class="constract-card pa-16 mb-16 d-flex bda-4 flex-column text_main"
  >
    <div class="d-flex justify-space-between mb-24 lh-32">
      <div class="d-flex">
        <div class="circle text_primary mr-8 text-center">
          <Icon name="wdht" />
        </div>
        <span>{{ cardDetail.companyName }}</span>
      </div>
      <div>
        <Tag size="large" :type="cardDetail.bizType === 1 ? 'primary' : 'warning'">
          {{ cardDetail.bizType === 1 ? '自营' : '撮合' }}
        </Tag>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <div>
        <div class="mb-12">
          <span class="text_muted">合同编号: </span>
          <span>{{ cardDetail.contractNo || '--' }}</span>
        </div>
        <div>
          <span class="text_muted">有效期至: </span>
          <span>{{ cardDetail.endTime ? dateFormat(cardDetail.endTime, 'yyyy年MM月dd日') : '--' }}</span>
        </div>
      </div>
      <div>
        <Button @click="handleJump" v-if="cardDetail.state === 1 || cardDetail.state === 0" type="primary">签约</Button>
        <div v-else class="text_muted font-18 mt-8">
          已签约
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { Tag, Button } from 'vant';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { useRouter } from '@/utils/compsitionHack';
import { useUserStore } from '@/store/user';
import ClientApp from '@/client/index';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'ConstractCard',
  components: {
    Tag,
    Button
  },
  props: {
    cardDetail: {
      type: Object
    }
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const dateFormat = (time, format) => {
      return formatToDateTime(time, format);
    };
    const handleJump = () => {
      router.push({
        path: PageEnum.CONTRACT_ONLINE,
        query: {
          driverId: userStore.driverId
        }
      });
    };
    const jumpPdf = pdfUrl => {
      // if (pdfUrl) {
      //   ClientApp.previewFile({
      //     url: pdfUrl
      //   });
      // }
    };
    return {
      handleJump,
      dateFormat,
      jumpPdf
    };
  }
});
</script>
<style lang="less">
.constract-card {
  background-color: white;
}
</style>
